
 html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
}

html {
  --scrollbarBG: #0D0D0D;
  --thumbBG: var(--1234213, linear-gradient(180deg, #000 0%, #15111B 100%));
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0; 
  overflow: visible; 
}
pre {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
a {
  background-color: transparent;
  text-decoration:none;
}
abbr[title] {
  border-bottom: none; 
  text-decoration: underline; 
  text-decoration: underline dotted; 
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%; 
  line-height: 1.15; 
  margin: 0; 
}
button,
input { 
  overflow: visible;
}
button,
select { 
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; 
  color: inherit; 
  display: table; 
  max-width: 100%; 
  padding: 0; 
  white-space: normal; 
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; 
  padding: 0; 
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; 
  outline-offset: -2px; 
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; 
  font: inherit; 
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}

@font-face {
  font-family: 'Cy Grotesk';
  src: url('fonts/cy-grotesk-grand-dark.otf');
}
@font-face {
  font-family: 'Nyasha Sans';
  src: url('fonts/nyasha_sans.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: #F5E3D9;
  background-image: 
    url('./img/pinkstar.svg'), 
    url('./img/redstar.svg'),
    url('./img/yellow.svg'),
    url('./img/blackblick.svg'),
    url('./img/flower.svg'),
    url('./img/rainbow.svg'),
    url('./img/circle.svg'),
    url('./img/linearrow.svg'),
    url('./img/message.svg'),
    url('./img/redstar1.svg'),
    url('./img/cactus.svg'),
    url('./img/yellow1.svg'),
    url('./img/arrow.svg'),
    /* url('./img/goal.svg'), */
    url('./img/goal.svg'),
    url('./img/rainbow1.svg'),
    url('./img/yellow2.svg'),
    url('./img/blackblick.svg'),
    url('./img/flower.svg'),
    url('./img/pink1.svg'),
    url('./img/pink2.svg');
  background-position: 
    left top, 
    right top, 
    98% top,
    10% 8%,
    85% 11%,
    105% 15%,
    10% 23%,
    53% 25%,
    70% 32%,
    94% 45%,
    31% 52%,
    48% 62%,
    89% 67%,
    14% 71%,
    10% 85%,
    49% 83%,
    68% 81%,
    81% 84%,
    bottom left,
    bottom right;
  background-repeat: 
    no-repeat;
}
@media (max-width: 1318px) {
  body {
    background-image: 
      url('./img/pinkstar.svg'), 
      url('./img/redstar.svg'),
      url('./img/yellow.svg'),
      url('./img/blackblick.svg'),
      url('./img/flower.svg'),
      url('./img/rainbow.svg'),
      url('./img/circle.svg'),
      url('./img/linearrow.svg'),
      url('./img/message.svg'),
      url('./img/redstar1.svg'),
      url('./img/cactus.svg'),
      url('./img/yellow1.svg'),
      url('./img/goal.svg'),
      url('./img/rainbow1.svg'),
      url('./img/yellow2.svg'),
      url('./img/blackblick.svg'),
      url('./img/flower.svg'),
      url('./img/pink1.svg'),
      url('./img/pink2.svg');
    background-position: 
      left top, 
      right top, 
      98% top,
      10% 8%,
      85% 11%,
      105% 15%,
      10% 23%,
      53% 25%,
      70% 32%,
      94% 45%,
      31% 52%,
      48% 62%,
      14% 71%,
      10% 85%,
      49% 83%,
      68% 81%,
      81% 84%,
      bottom left,
      bottom right;
  }
}

.bg-wrapper {
  display: block;
  margin: 0 auto;
}
.yes {
  margin: 0 auto;
  display: flex;
  max-width: 1373px;
  justify-content: space-between;
  margin-top: 233px;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  animation: yesEffect 2s;
  animation-iteration-count: 1;
}
@keyframes yesEffect {
  0% { transform: translate(0, -300px); }
  100% { transform: translate(0, 0px); }
}
.yes__text {
  color: #000;
  text-align: center;
  font-family: "Nyasha Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.yes__circle {
  background-color: #000;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yes__circle_inner {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #F7D046;
}


.title {
  color: #000;
  text-align: center;
  text-shadow: -10.863px 10.863px 0px #FFD703;
  font-family: "Cy Grotesk";
  font-size: 130px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
  animation: titleEffect 5s;
  animation-iteration-count: 1;
}
@keyframes titleEffect {
  0% { transform: translate(-500px, 0); }
  50% { transform: translate(300px, 0); }
  100% { transform: translate(0, 0); }
}

@media (max-width: 1264px) {
  .yes__text {
    font-size: 30px;
  }
}
@media (max-width: 1200px) {
  .yes {
    margin-top: 90px;
  }
}

@media (max-width: 889px) {
  .title {
    font-size: 70px;
  }
  .yes__circle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .yes {
    margin-top: 150px;
    flex-direction: column;
  } 
}
@media (max-width: 488px) {
  .title {
    font-size: 50px;
  }
}

.post {
  overflow: hidden;
  max-width: 1373px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.post__link {
  display:block;
  padding: 10.491px 29.141px;
  color: #000;
  text-align: center;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}
.post__link:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFD703;
  transform: skew(20deg);
  z-index: -1;
}
.post__links {
  display: flex;
  flex-wrap: wrap;
  max-width: 725px;
  gap: 16px;
  justify-content: center;
}

.post__cat {
  position: absolute;
  width: 300px;
  height: auto;
  left: 65%;
  top: 30%;
  transform: rotate(-20deg);
  animation: postEffect 3s;
  animation-iteration-count: infinite;
}
@keyframes postEffect {
  0% { transform: translate(5px, -6px) rotate(-24deg); }
  20% { transform: translate(-7px, 4px) rotate(-16deg); }
  40% { transform: translate(5px, -7px) rotate(-17deg); }
  60% { transform: translate(-7px, 5px) rotate(-23deg); }
  80% { transform: translate(-5px, -5px) rotate(-16deg); }
  100% { transform: translate(5px, -6px) rotate(-24deg); }
}
@media (max-width: 1316px) {
  .post {
    flex-direction: column;
    align-items: center;
  }
  .post__links {
    margin-top: 700px;
  }
  .post__cat {
    left: auto;
    right: auto;
    top: 53%;
    transform: rotate(-20deg);
  }
  .post__img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 599px) {
  .post__cat {
    top: 48%;
  }
}
@media (max-width: 533px) {
  .post__cat {
    top: 44%;
  }
}
@media (max-width: 438px) {
  .post__cat {
    top: 36%;
  }
}
.looking {
  max-width: 1373px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 359px;
  position: relative;
}
@media (max-width: 1200px) {
  .looking {
    margin-top: 70px;
  }
}
.looking__title {
  max-width: 937px;
  width: 100%;
  color: #000;
  text-shadow: -10.863px 10.863px 0px #F7D046;
  font-family: "Cy Grotesk";
  font-size: 86px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 120.4px */
  text-transform: uppercase;
  animation: titleEffect 5s;
  animation-iteration-count: 1;
}
@keyframes lookingTitle {
  0% { transform: translate(-500px, 0); }
  50% { transform: translate(300px, 0); }
  100% { transform: translate(0, 0); }
}
.looking__text {
  max-width: 593px;
  width: 100%;
  margin-top: 65px;
  color: #000;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: lookingTextEffect 3s;
  animation-iteration-count: 1;
}
@keyframes lookingTextEffect {
  0% { transform: translate(0, 60px); }
  33% { transform: translate(0, 0px); }
  66% { transform: translate(0, 40px); }
  100% { transform: translate(0, 0px); }
}
.looking__link {
  display:block;
  padding: 10.491px 29.141px;
  color: #000;
  text-align: center;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: max-content;
  margin-top: 65px;
}
.looking__link:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFD703;
  transform: skew(-20deg);
  z-index: -1;
}
.looking__lightning {
  position: absolute;
  top: -10%;
  left: 80%;
  animation: lightningEffect 3s;
  animation-iteration-count: infinite;
}
@keyframes lightningEffect {
  0% { transform: translate(0, 17px) }
  50% { transform: translate(0, -17px) }
  100% { transform: translate(0, 17px) }
}
.looking__plush {
  position: absolute;
  top: 35%;
  left: 63%;
  width: 550px;
  height: auto;
  animation: plushEffect 3s;
  animation-iteration-count: infinite;
}
@keyframes plushEffect {
  0% { transform: translate(0, -17px) }
  50% { transform: translate(0, 17px) }
  100% { transform: translate(0, -17px) }
}
@media (max-width: 1452px) {
  .looking__lightning {
    top: 40%;
    left: 60%;
    z-index: -100;
  }
  .looking__plush {
    top: 85%;
    left: 45%;
    z-index: -100;
  }
}
@media (max-width: 1024px) {
  .looking__lightning {
    top: 100%;
    left: 35%;
  }
  .looking__plush {
    top: 135%;
    left: 10%;
  }
}
@media (max-width: 920px) {
  .looking {
    padding-left: 22px;
    padding-right: 22px;
  }  
}
@media (max-width: 735px) {
  .looking__lightning {
    top: 110%;
    left: 40%;
  }
  .looking__plush {
    width: 350px;
    height: auto;
    left: 25%;
  }
}
@media (max-width: 594px) {
  .looking__plush {
    left: 7%;
    top: 145%;
  }
  .looking__lightning {
    left: 40%;
  }
}
@media (max-width: 421px) {
  .looking__plush {
    width: 250px;
    height: auto;
    left: 10%;
  }
}

@media (max-width: 600px) {
  .looking__title {
    font-size: 55px;
  }
  .looking {
    padding-left: 15px;
    padding-right: 15px;
  }

  .looking__link {
    margin-top: 35px;
  }
}
@media (max-width: 600px) {
  .looking__title {
    font-size: 45px;
  }
  .looking__text {
    font-size: 28px;
  }
}
.join {
  max-width: 1373px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 352px;
  position: relative;
}
.join__title {
  color: #000;
  text-align: right;
  text-shadow: -17.895px 17.895px 0px #F7D046;
  font-family: "Cy Grotesk";
  font-size: 140px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 196px */
  text-transform: uppercase;
}
.join__text {
  color: #000;
  text-align: right;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 32px;
  margin-bottom: 136px;
}
@media (max-width: 1099px) {
  .join__text {
    margin-bottom: 68px;
  }
}
.infoblock {
  width: max-content;
  margin-bottom: 66px;
}
@media (max-width: 1099px) {
  .infoblock {
    margin-bottom: 44px;
  }
}
.infoblock__supply {
  margin-left: 30%;
}
.infoblock__taxes {
  margin-left: 40%;
}
.infoblock__tiker {
  margin-left: 50%;
}
.infoblock__title {
  margin-bottom: 12px;
  color: #000;
  text-shadow: -10.863px 10.863px 0px #F7D046;
  font-family: "Cy Grotesk";
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; 
  text-transform: uppercase;
}
.infoblock__text {
  color: #000;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.join__cat {
  position: absolute;
  top: -10%;
  left: -20%;
  z-index: -100;
  animation: joinCatEffect 5s;
  animation-iteration-count: infinite;
}

@keyframes joinCatEffect {
  0% { transform: translate(20px, 20px) rotate(5deg); }
  25% { transform: translate(20px, -20px) rotate(-5deg); }
  50% { transform: translate(-20px, -20px) rotate(5deg); }
  75% { transform: translate(-20px, 20px) rotate(-5deg); }
  100% { transform: translate(20px, 20px) rotate(5deg); }
}


@media (max-width: 1024px) {
  .join {
    margin-top: 700px;
  }
  .join__title {
    font-size: 100px;
  }
  .infoblock__supply {
    margin-left: 10%;
  }
  .infoblock__taxes {
    margin-left: 20%;
  }
  .infoblock__tiker {
    margin-left: 30%;
  }
}
@media (max-width: 735px) {
  .join {
    margin-top: 700px;
  }
}
@media (max-width: 600px) {
  .join {
    margin-top: 550px;
  }
  .join__title {
    font-size: 60px;
  }
  .join__text {
    font-size: 28px;
  }
}
@media (max-width: 750px) {
  .infoblock__title {
    font-size: 48px;
  }
  .infoblock__text {
    font-size: 36px;
  }
}
@media (max-width: 500px) {
  .infoblock__supply {
    margin-left: 0%;
  }
  .infoblock__taxes {
    margin-left: 5%;
  }
  .infoblock__tiker {
    margin-left: 10%;
  }
}
@media (max-width: 420px) {
  .infoblock__title {
    font-size: 36px;
  }
  .infoblock__text {
    font-size: 28px;
  }
}

@media (max-width: 1824px) {
  .join__cat {
    position: absolute;
    top: -10%;
    left: -10%;
    z-index: -100;
  }
}
@media (max-width: 1557px) {
  .join__cat {
    top: 15%;
    left: 0%;
    z-index: -100;
  }
}
@media (max-width: 1200px) {
  .join__cat {
    top: 105%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: -100;
  }
}
@media (max-width: 766px) {
  .join__cat {
    width: 500px;
    height: auto;
  }
}
@media (max-width: 575px) {
  .join__cat {
    width: 400px;
  }
}
@media (max-width: 475px) {
  .join__cat {
    width: 300px;
  }
}

.narrative {
  color: #000;
  text-align: center;
  text-shadow: -10.863px 10.863px 0px #F7D046;
  font-family: "Cy Grotesk";
  font-size: 88px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 123.2px */
  text-transform: uppercase;
  max-width: 1312px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 362px;
}
@media (max-width: 1200px) {
  .narrative {
    margin-top: 900px;
    font-size: 66px;
  }
}
@media (max-width: 900px) {
  .narrative {
    font-size: 44px;
  }
}
@media (max-width: 766px) {
  .narrative {
    margin-top: 720px;
    font-size: 33px;
  }
}
@media (max-width: 600px) {
  .narrative {
    font-size: 26px;
  }
}
@media (max-width: 567px) {
  .narrative {
    margin-top: 600px;
  }
}
.trio {
  margin: 0 auto;
  display: flex;
  align-content: center;
  max-width: 1200px;
  width: 100%;
  align-items: end;
  animation: plushEffect 3s;
  animation-iteration-count: infinite;
}
.trio__cat1 {
  max-height: 450px;
  width: 40.68%;
  animation: joinCatEffectInner 5s;
  animation-iteration-count: infinite;
}
@media (max-width: 919px) {
  .trio__cat1 {
    max-height: 300px;
  }
}
@keyframes joinCatEffectInner {
  0% { transform: translate(20px, 20px) }
  25% { transform: translate(20px, -20px) }
  50% { transform: translate(-20px, -20px) }
  75% { transform: translate(-20px, 20px) }
  100% { transform: translate(20px, 20px) }
}
.trio__cat2 {
  height: auto;
  width: 32.07%;
  animation: postEffectInner 3s;
  animation-iteration-count: infinite;
}
@keyframes postEffectInner {
  0% { transform: translate(5px, -6px) }
  20% { transform: translate(-7px, 4px) }
  40% { transform: translate(5px, -7px) }
  60% { transform: translate(-7px, 5px) }
  80% { transform: translate(-5px, -5px) }
  100% { transform: translate(5px, -6px)  }
}
.trio__cat3 {
  height: auto;
  width: 27.25%;
}
@media (max-width: 475px) {
  .narrative {
    margin-top: 500px;
  }
}
.ca {
  color: #000;
  text-shadow: -5.431px 5.431px 0px #F7D046;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 1449px) {
  .ca {
    font-size: 36px;
    word-break: break-all;
  }
}
@media (max-width: 600px) {
  .ca {
    font-size: 24px;
  }
}
.links {
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.links__link {
  display:block;
  padding: 10.491px 29.141px;
  color: #000;
  text-align: center;
  font-family: "Nyasha Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}
.links__link:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFD703;
  transform: skew(20deg);
  z-index: -1;
}
@media (max-width: 1092px) {
  .links {
    margin-top: 35px;
  }
}
.tools {
  margin: 0 auto;
  display: flex;
  gap: 60px;
  width: max-content;
  margin-top: 60px;
}
.tools__tool {
    display:block;
}

@media (max-width: 800px) {
  .tools {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: max-content;
    margin-top: 60px;
  }
}
@media (max-width: 800px) {
  .tools {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: max-content;
    margin-top: 60px;
  }
}
@media (max-width: 420px) {
  .tools__pic {
    width: 320px;
    height: auto;
  }
}
.copy {
  margin: 180px auto 47px auto;
  color: #000;
  opacity: 0.4;
  text-align: center;
  font-family: "Nyasha Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}